import * as React from 'react'
import styled from '@emotion/styled'
import Layout from '../components/layout'
import Seo from '../components/seo'

import { HomeSplash, RowWrapper, TextCenter } from './contact.module.css'

const GMaps =
  'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2610.6270269143533!2d-122.31708448384494!3d49.13171827931527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x548433f7e0655555%3A0xae426f88c3e494af!2s32885%20Mission%20Way%20Unit%20110%2C%20Mission%2C%20BC%20V2V%206E4!5e0!3m2!1sen!2sca!4v1623874282949!5m2!1sen!2sca' // google maps embed

const GStyle = {
  border: `0`,
}

// const JotformUrl = "" //Jotform embed URL from Iframe

// const IStyle = {
//   width: `100%`,
//   height: `580px`,
//   overflow: `hidden`, // hide scrolls
//   scrolling: `no`,
//   border: `none`,
//   margin: `0 0 3em 0`,
//   padding: `0`,
// }

const Heading = styled.h1`
  color: var(--blue);
  text-align: center;
`

const StyledA = styled.a`
  font-size: 2rem;
  color: black;
  margin: 0;
`

const Contact = () => (
  <Layout>
    <Seo title="Kustom Kreations Welding Contact" description="Call Us Today" />
    <div className={HomeSplash}>
      <iframe
        title="Kustom Kreations Address"
        src={GMaps}
        width="600"
        height="500"
        frameborder="0"
        style={GStyle}
        allowfullscreen=""
        aria-hidden="false"
        disable="geolocation; microphone; camera"
      ></iframe>
    </div>

    <div className={RowWrapper} style={{ marginTop: `3em` }}>
      <Heading>Contact Kustom Kreations</Heading>
      <p className={TextCenter} style={{ marginBottom: `64px` }}>
        Call us today with any questions you have:{' '}
        <StyledA href="tel:+16043134998" title="Contact Kustom Kreations">
          604.313.4998
        </StyledA>
      </p>
      <p className={TextCenter} style={{ marginBottom: `64px` }}>
        Alternatively, email us at{' '}
        <StyledA
          href="mailto:kustomkreations@shaw.ca"
          title="kustom kreations welding email"
        >
          kustomkreations@shaw.ca
        </StyledA>
        .
      </p>
    </div>
  </Layout>
)

export default Contact
